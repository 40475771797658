import { createApp } from 'vue'

import App from './App.vue'
import router from "./router";
import axios from 'axios'; 
import jquery from "jquery";
import VueApexCharts from "vue3-apexcharts";
import vClickOutside from "click-outside-vue3";
import { registerScrollSpy } from 'vue3-scroll-spy';

import Maska from 'maska'
import i18n from "./i18n"
//  import { initFirebaseBackend } from './authUtils'
//  import { configureFakeBackend } from './helpers/fake-backend';

import BootstrapVueNext from 'bootstrap-vue-next'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
// datatable
import 'bootstrap'
import 'datatables.net-bs5'
// // Example: import buttons and plugins
import 'datatables.net-buttons/js/dataTables.buttons.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'

// import the rest for your specific theme
import 'datatables.net-buttons-bs5'
import 'datatables.net-select-bs5'

import 'datatables.net-select-bs5/css/select.bootstrap5.min.css'
import 'datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import 'sweetalert2/dist/sweetalert2.min.css';
import '@vueform/slider/themes/default.css';

import store from "./state/store";

import "../src/design/app.scss";
import Swal from 'sweetalert2';
import VueGoogleMaps from '@fawmi/vue-google-maps'

 const appInstance =createApp(App)
 appInstance.use(store)
 appInstance.use(router)
 appInstance.use(VueGoogleMaps, {
  load: {
      key: 'AIzaSyDIOCogwqXM6RPDfXSuKwvF6OfXoPaxUbM',
      // language: 'de',
  },
})
//sentry 
Sentry.init({
  appInstance,
  dsn: "https://10191f09973b524ce8f64ed86e636cc5@o4508314024083456.ingest.us.sentry.io/4508908118016000",
  integrations: [
    new BrowserTracing({ 
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    })
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions  
  tracePropagationTargets: ["dashboard.smartgiftapp.com", /^https:\/\/dashboard\.smartgiftapp\.com\/api/],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
 appInstance.use(require('vue-chartist'))
 appInstance.use(BootstrapVueNext)
 appInstance.use(VueApexCharts)
 appInstance.use(vClickOutside)
 appInstance.use(i18n)
  appInstance.use(registerScrollSpy)
  .use(Maska)
  .mount('#app') 


 appInstance.config.globalProperties.$http = axios
 appInstance.config.globalProperties.$hostUrl = process.env.VUE_APP_URL 
 appInstance.config.globalProperties.$ = jquery
 appInstance.config.globalProperties.$router = router
 appInstance.config.globalProperties.$formatNumber = function (number) {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number);
};
 // axios interceptors
 axios.interceptors.request.use(req => {
  const currentPath = router.currentRoute.value.path; // Get the current route path
  if (currentPath !== '/orders' && currentPath !== '/' && currentPath !== '/login') {
    jquery('.spinner-container').show();
  }
  req.headers['Accept-Language'] = 'ar'
  req.headers.authorization = 'Bearer ' + localStorage.token
  return req
})
axios.interceptors.response.use(function (response) {
   jquery('.spinner-container').hide()
  return response
}, function (error) {
  // (jquery('.spinner-container') ? jquery('.spinner-container').remove() : jquery('.spinner-container').show())
  console.log(error)
  jquery('.spinner-container').hide()
  if (error?.response?.status === 401) {
   Swal.fire({
      title: 'انتهت الجلسة',
      text: 'انتهت صلاحية جلسة العمل الخاصة بك. هل ترغب في إعادة توجيهك إلى صفحة تسجيل الدخول؟',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Yes',
      closeOnConfirm: false
    }).then(() => {
      router.push({ path: '/login' })
    })
  } else {
    console.log(error)
  }
  return Promise.reject(error)
})


appInstance.mixin({

  methods: {
    async postRequest (formData, url, create) {
      let errors = []

      await this.$http.post(`${url}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
        }
      })
        .then((response) => {
          console.log(response)
          if (!response.data.error) {
            console.log(response.data.message)
            Swal.fire({
              position: 'top-center',
              icon: 'success',
              title: response.data.message,
              showConfirmButton: false,
              timer: 1500
            })
            if (create) {
              this.$router.push({ path: create })
            }
          } else {
            errors = response.data.data
            console.log(errors, 'errors1')
          }
        }).catch(function (error) {
          if (Object.prototype.hasOwnProperty.call(error, 'response')) {
            jquery('.spinner-container').hide()
            errors =error.response.data.data
            if(error.response?.data?.message){
              Swal.fire({
                position: 'top-center',
                icon: 'error',
                title: error.response?.data?.message,
                showConfirmButton: false,
                timer: 2000
              })
            }
            
          //   } else {
          //     swal({
          //       title: 'حدث خطـأ اثناء معالجة الطلب الرجاء المحاولة لاحقا',
          //       buttons: 'تم'
          //     })
          //   }
          // } else {
          //   swal({
          //     title: 'حدث خطـأ اثناء معالجة الطلب الرجاء المحاولة لاحقا',
          //     buttons: 'تم'
          //   })
          }
        })
      return errors
    },
}
})



